import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import ExistingMandate from "../components/existing-mandate";
import SetAsPrimary from "../components/set-as-primary";
import {
  userProfile,
  getMandateList,
  usersBank,
  getPendingMandate,
  getRetriggerMandate,
} from "../apisMethods";
import { notify } from "./toastCreater";
import { globalInfo } from "../App";

const Bank_Mandate = () => {
  const { setLoader } = useContext(globalInfo);
  const navigate = useNavigate();

  const [existingmandateShow, setExistingmandateShow] = useState(false);
  const [userProfileData, setUserProfileData] = useState([]);
  const [mandateListData, setMandateListData] = useState([]);
  const [headerState, setHeaderState] = useState([]);
  const [usersBankDetail, setUsersBankDetail] = useState([]);
  const [filteredMandate, setFilteredMandate] = useState([]);
  const [selectedProfileName, setSelectedProfileName] = useState("");
  const [userIIn, setUserIIn] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    const userdata = JSON.parse(localStorage.getItem("user"));
    setHeaderState(headers);
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers);
      usersBankCall(userdata?.iin, headers);
      setSelectedProfileName(userdata?.displayName);
      setUserIIn(userdata?.iin);
    }
  }, []);

  const userProfileCall = (pan, header) => {
    let tempArr = [];
    userProfile({ pan }, header).then((res) => {
      res.data?.data.forEach((data, i) => {
        let obj = {
          value: data?.PAN ? `${i}${data?.PAN}` : `${i}Gpan_${data?.GUARD_PAN}`,
          label: `${data?.NAME} ${data.JOINT_NAME1 && "|"} ${data.JOINT_NAME1}`,
          body: { iin: data?.IIN, name: data?.NAME },
        };
        tempArr.push(obj);
      });
    });
    setUserProfileData(tempArr);
  };
  const profileChangeHandler = (e) => {
    setSelectedProfileName(e?.body?.name);
    usersBankCall(e?.body?.iin, headerState);
    setUserIIn(e?.body?.iin);
  };

  const usersBankCall = (iin, headers) => {
    setLoader("block");
    usersBank({ iin }, headers).then((res) => {
      if (!res.data) {
        setUsersBankDetail([]);
      } else {
        setUsersBankDetail(res.data?.data);
        getMandateListCall(iin, headers);
      }
      setLoader("none");
    });
  };

  const getMandateListCall = (iin, headers) => {
    getMandateList({ iin }, headers).then((res) => {
      if (!res.data) {
        setMandateListData([]);
        // notify("warn", "you have no mandate list");
      } else {
        setMandateListData(res.data?.data);
      }
    });
  };

  const getMandateListFilter = (accountNo) => {
    let tempArr = [];
    let obj = {};
    for (let i = 0; i < mandateListData.length; i++) {
      if (accountNo === mandateListData[i].ACCOUNT_NO) {
        obj = {
          ...obj,
          UMRN_NO: mandateListData[i].UMRN_NO,
          AMOUNT: mandateListData[i].AMOUNT,
          FROM_DATE: mandateListData[i].FROM_DATE,
          TO_DATE: mandateListData[i].TO_DATE,
        };
        // tempArr.push()
        tempArr = [...tempArr, obj];
      }
    }
    setExistingmandateShow(true);
    setFilteredMandate(tempArr);
  };

  const goToMandate = (data) => {
    data.bankHolderName = selectedProfileName;
    navigate("/dashboard/create-mandate", {
      state: data,
    });

  };

  const openModalForPendingMandate = (accountNo) => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };

    getPendingMandate(
      {
        cust_id: userIIn,
        status: "FNR",
      },
      headers
    ).then((res) => {
      if (!res.data) {
        // notify("error", "error to find pending mandate");
        let filteredList = []
        filteredList.trigger = true;
        setExistingmandateShow(true);
        setFilteredMandate(filteredList);
      } else {
        const filteredList = res.data.data.filter((el) => {
          let givenDateString = el?.EXPIRED_DATE_TIME;
          let givenDate = new Date(givenDateString);
          let currentDate = new Date();
          console.log(accountNo === el.ACCOUNT_NO && el.ACH_MANDATE_STATUS === "PENDING" && el.PROCESS_MODE === 'eMandate');
          return (
            accountNo === el.ACCOUNT_NO && el.ACH_MANDATE_STATUS === "PENDING" && el.PROCESS_MODE === 'eMandate'
            &&
            givenDate > currentDate
          );
        });
        filteredList.trigger = true;
        setExistingmandateShow(true);
        setFilteredMandate(filteredList);
      }
    });
  };

  const getLink = (ref_no) => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    getRetriggerMandate(
      {
        iin: userIIn,
        unique_ref_no_feed: ref_no,
      },
      headers
    ).then(res => {
      if (!res.data) {
        notify("error", "failed to  fetch link");
      } else {
        window.open(res.data.data.eMandatelink);
      }
    })
  };

  return (
    <>
      <div className="wrapper px-2">
        <div className="report px-lg-5 ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Bank Details and Mandate
              </li>
            </ol>
          </nav>
        </div>
        <section className="mb-5">
          <div className="container-fluid">
            <div className="row justify-content-between px-lg-4 pt-3 mx-lg-4">
              <div className="col-md-4">
                <div className="my-order px-lg-3">
                  <label className="pb-2">Select Profile</label>
                  <Select
                    options={userProfileData}
                    onChange={profileChangeHandler}
                    className="orderprofile"
                    placeholder={selectedProfileName}
                  />
                </div>
              </div>
              <div className="col-md-3  pt-4 mt-3">
                {selectedProfileName && (
                  <Link
                    className="btn-custom"
                    to="/dashboard/add-bank"
                    state={{ iin: userIIn }}
                  >
                    <span className="pe-2">
                      <FaPlus />
                    </span>
                    Add Another Bank
                  </Link>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className=" m-lg-5">
          <div className="mb-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="order-shadow p-4 mb-4">
                    <Table responsive>
                      <thead>
                        <tr className="text-red">
                          <th className="wb-16">Bank Name</th>
                          <th className="wb-10">Account Number</th>
                          <th className="wb-10">Status</th>
                          <th className="wb-10">IFSC Code</th>
                          <th className="wb-10">Account Type</th>
                          <th className="wb-10">Branch Name</th>



                          <th className="wb-14">Create Mandate</th>

                          <th className="wb-16">Existing Mandate</th>
                          <th className="wb-16">Pending Mandate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {usersBankDetail.length > 0
                          ? usersBankDetail.map((data) => {
                            return (
                              <>
                                <tr>
                                  <td>
                                    {data.BANK_NAME}
                                    {/* <SetAsPrimary /> */}
                                    {data.DEFAULT_BANK === "Y" ? (
                                      <span className="text-secondary ps-2">
                                        (Primary Bank)
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>{data.AC_NO}</td>
                                  <td>
                                    {data.STATUS === "Activated" ? (
                                      <>
                                        <span className="pe-2">
                                          <FaCheck color={"#1cc88a"} />
                                        </span>{" "}
                                        <span className="pl-2 text-secondary">
                                          Verified
                                        </span>
                                      </>
                                    ) : (
                                      <span className="pl-2 text-danger">
                                        Not Verified
                                      </span>
                                    )}
                                  </td>
                                  <td>{data.IFSC_CODE}</td>
                                  <td>{data.AC_TYPE}</td>
                                  <td>{data?.BRANCH_NAME}</td>
                                  <td>
                                    {data.STATUS === "Activated" ? <span
                                      onClick={() => goToMandate(data)}
                                      className="btn btn-danger btn-sm shadow-sm text-decoration-none fs-sm-10"
                                    >
                                      <span className="pe-2">
                                        <FaPlus />
                                      </span>
                                      Create E-Mandate
                                    </span> : <span
                                      className="btn btn-danger-disabled fs-sm-10 btn-sm shadow-sm text-decoration-none"
                                    >
                                      <span className="pe-2">
                                        <FaPlus />
                                      </span>
                                      Create E-Mandate
                                    </span>
                                    }

                                  </td>
                                  <td>
                                    {data.STATUS === "Activated" ?
                                      <span
                                        className="btn btn-danger btn-sm shadow-sm text-decoration-none fs-sm-10"
                                        onClick={() =>
                                          getMandateListFilter(data.AC_NO)
                                        }
                                      >
                                        <span className="pe-2">
                                          <FaEye />
                                        </span>
                                        View existing mandate
                                      </span> : <span
                                        className="btn btn-danger-disabled btn-sm fs-sm-10 shadow-sm text-decoration-none"

                                      >
                                        <span className="pe-2">
                                          <FaEye />
                                        </span>
                                        View existing mandate
                                      </span>}
                                  </td>

                                  <td>
                                    {data.STATUS === "Activated" ?
                                      <span
                                        className="btn btn-danger btn-sm shadow-sm text-decoration-none fs-sm-10"
                                        onClick={() =>
                                          openModalForPendingMandate(data.AC_NO)
                                        }
                                      >
                                        <span className="pe-2">
                                          <FaEye />
                                        </span>
                                        View Pending Mandate
                                      </span> : <span
                                        className="btn btn-danger-disabled btn-sm shadow-sm text-decoration-none fs-sm-10 "

                                      >
                                        <span className="pe-2">
                                          <FaEye />
                                        </span>
                                        View Pending Mandate
                                      </span>}
                                  </td>
                                </tr>
                              </>
                            );
                          })
                          : ""}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section>
        <ExistingMandate
          show={existingmandateShow}
          setShow={setExistingmandateShow}
          filteredMandate={filteredMandate}
          getLink={getLink}
        />
      </section>
    </>
  );
};
export default Bank_Mandate;
