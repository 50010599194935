import { apis, request } from "../request";



export const createAchMandate = async (body, headers) => {
    return await request(apis.post_createAchMandate, body, headers);
  };
  export const addBank = async (body, headers) => {
    return await request(apis.post_addBank, body, headers);
  };
  export const uploadBankProof = async (body, headers) => {
    return await request(apis.post_uploadBankProof, body, headers);
  };
  export const updateNseMultiUserBankdetails = async (body, headers) => {
    return await request(apis.post_updateNseMultiUserBankdetails, body, headers);
  };