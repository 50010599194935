import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Notification_Popup = ({show,setShow,data})=>{
return (
    <>

       <Modal show={show} backdrop="static" keyboard={false} onHide={()=>setShow(false)} scrollable={true}   className="animate__animated animate__zoomIn animate__fast">
       <Modal.Header closeButton className="bg-gray ">
          <Modal.Title className="text-red fs-5">{data?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-gray">
        <p className='fs-13'>
          {data?.body}
        </p>
        {/* <p className='fs-13'>The change in Folio numbers will be effective from 26 June 2022.</p>
        <h6 className='text-black '> Example 1 </h6>
        <p className='fs-13 mb-0'>
          Old Folio No - 12345 /88
        </p>
        <p className='fs-13 '>New Folio No - 10012345 /88</p>
        <h6 className='text-black '> Example 2 </h6>
        <p className='fs-13 mb-0'>
          Old Folio No - 1234 /99
        </p>
        <p className='fs-13 mb-0'>New Folio No - 10001234 /99</p> */}
        </Modal.Body>
        <Modal.Footer className=" bg-gray">
          <Button variant="danger" className="btn-custom"  onClick={()=>setShow(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default Notification_Popup;