import React, { useState, useEffect,useContext} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {
  getMandateList,
  getNSEProductCode,
  getUserProfileViaPortfolio,
  newPurchase,
  usersBank,
  saveNseTransaction,
  userProfile
} from "../apisMethods";
import { notify } from "../pages/toastCreater";
import TransactSuccess from "./transact-success";
import SWW from "./something-went-wrong";
import { globalInfo } from "../App";
import { disableFutureDate, formatDate } from "../currentMonth/FinancialYear";
import ConfirmationMandatePopup from "./mandateConfirmationPopup";
const Additional_Purchase_Popup = ({ data, show, setShow }) => {
  const {setLoader} = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);
  const [amount, setAmount] = useState()
  const [paymentMode, setPaymentMode] = useState('')
  const [hideRTGS, setHideRTGS] = useState("d-none");
  const [hideMandate, setHideMandate] = useState("d-none");
  const [hideImidiatePayment, setHideImidiatePayment] = useState("d-none");
  const [imediateOrEmail, setImediateOrEmail] = useState("Y");
  const [mandateListData, setMandateListData] = useState([]);
  const [mandateUrn, setMandateUrn] = useState('');
  const [tempMandateUrn, setTempMandateUrn] = useState({});
  const [headerState, setHeaderState] = useState({});
  const [bankName, setBankName] = useState("");
  const [tempBankName, setTempBankName] = useState({});
  const [usersBankDetail, setUsersBankDetail] = useState([]);
  const [utrNo, setUtrNo] = useState('');
  const [transferDate, setTransferDate] = useState('');
  const [useProfileData, setUseProfileData] = useState({});
  const [hideBank, setHideBank] = useState('d-none');
  const [mandateLoder, setMandateLoder] = useState(false);
  const [bankLoder, setBankLoder] = useState(false);
  const [futureDateHide, setFutureDateHide] = useState("");
  const [isMandateAvailable, setIsMandateAvailable] = useState(false)
  useEffect(() => {

    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers);
    setFutureDateHide(disableFutureDate())
  }, [])


  const amountHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters
    setAmount(sanitizedValue);
  };
  const changePaymentModeHandler = async (e) => {
    let value = e.target.value;
    setBankName('')
    setTempMandateUrn({})
    setTempBankName({})
    setUtrNo('')
    setTransferDate('')
    setPaymentMode(e.target.value);
    let reqBodyForIin = {
      folio: data?.folio,
      productcode: data?.productcode,
      rta: data?.rta,
      gpan: data?.gpan ? data?.gpan : ""
    }
    let iinValue = ""
    let res = await getUserProfileViaPortfolio(reqBodyForIin, headerState)

    if (!res.data) {
      notify("warn", "something is else")
      return
    } else {
      setUseProfileData(res.data?.data)
      iinValue = res.data?.data?.iin
    }

    usersBankCall(iinValue)
    if (value === "OL" || value === "UPI") {
      setHideRTGS("d-none");
      setHideMandate("d-none");
      setHideImidiatePayment("");
      setMandateUrn('')
      setMandateListData([])
      setHideBank("")
    } else if (value === "TR") {
      setHideRTGS("");
      setHideBank("")
      setHideMandate("d-none");
      setHideImidiatePayment("d-none");
      setMandateUrn('')
      setMandateListData([])
    } else if (value === "M") {
      setHideMandate("");
      setHideBank("d-none")
      setHideRTGS("d-none");
      setHideImidiatePayment("d-none");
      setBankName("")
      setTempBankName({})
      getMandateListCall(iinValue)
      setUsersBankDetail([]);

    }
  };
  const getMandateListCall = (iin) => {
    setMandateLoder(true)
    getMandateList({ iin }, headerState).then(res => {
      if (!res.data) {
        setMandateListData([])
      } else {
        setMandateListData(res.data?.data)
      }
      setMandateLoder(false)
    })
  }
  const usersBankCall = async (iin) => {
    setBankLoder(true)
    await usersBank({ iin }, headerState).then((res) => {
      if (!res.data) {
        setUsersBankDetail([]);
      } else {
        setUsersBankDetail(res.data?.data);
      }
      setBankLoder(false)
    });
  };
  const refreshState = () => {
    setAmount('')
    setPaymentMode('')
    setBankName('')
    setTempBankName({})
    setMandateUrn('')

  }

  const changBankHandler = (e) => {
    if (e.target.value) {
      let bankDetailObj = JSON.parse(e.target.value);
      setBankName(e.target.value);
      setTempBankName(bankDetailObj);
    }
  };
  const orderAdditional = async () => {
    if (!amount) {
      notify("error", "All Fields Required.");
      return;
    }
    if (!paymentMode) {
      notify("error", "Please select payment mode.");
      return;
    }
    if (amount < 5000) {
      notify("warn", "Amount should be greater than min amount");
      return;
    }
    if (!bankName && paymentMode !== "M") {
      notify("error", "Please select bank name");
      return;
    }
    if (paymentMode === "M" && !mandateUrn) {
      notify("error", "Please select mandate ");
      return;
    }
    let nseData = {}
    let nseReqBody = {
      schemecode: useProfileData?.schemecode
    }
    const nseRes = await getNSEProductCode(nseReqBody, headerState)
    if (!nseRes.data) {
      notify("warn", "nse product code not found please try again")
    } else {
      nseData = {
        productcode: nseRes.data?.data?.productcode,
        reinvestTag: nseRes.data?.data?.REINVEST_TAG,
      }
    }
    setLoader("block")
    let reqData = {
      email: useProfileData.email,
      tax_status: useProfileData?.taxstatus,
      sub_trxn_type: "N",
      trxn_acceptance: "ALL",
      payment_mode: paymentMode,
      instrm_amount: amount,
      debit_amount_type: "",
      Return_paymnt_flag: paymentMode === "OL" || paymentMode === "UPI" ? imediateOrEmail : "N",
      Client_callback_url: "API URL",
      ach_exist: "Y",
      amc: useProfileData?.amccode,
      product_code: nseData?.productcode,
      reinvest: nseData?.reinvestTag,
      amount: amount,
      input_ref_no: "",
      perpetual_flag: "N",
      insurance_enabled: "N",
      instrm_date: "",
      rtgs_code: paymentMode === "TR" ? tempBankName.IFSC_CODE : "",
      umrn: paymentMode === "M" ? tempMandateUrn.UMRN_NO : "",
      folio: data.folio,
      iin: useProfileData?.iin,
      accountNo: paymentMode === "M" ? tempMandateUrn.ACCOUNT_NO : tempBankName.AC_NO,
      bank_code: paymentMode === "M" ? tempMandateUrn.BANK_CODE : tempBankName.BANK_CODE,
      ifsc_code: paymentMode === "M" ? "" : tempBankName.IFSC_CODE,
      branch: paymentMode === "M" ? "" : tempBankName.BRANCH_NAME,
      holder_name: data.name,
      transfer_date: formatDate(transferDate),
      utr_no: utrNo,
    };
    newPurchase(reqData, headerState).then((res) => {
      if (res.data?.status === 200) {
        setConfirmationData(res.data?.data);
        setConfirmShow(true);
        setShow(false);
        let nseDataForSave = {
          Unique_No: res.data.data?.Unique_No,
          Trxn_No: res.data.data?.Trxn_No,
          Application_No: res.data?.data.Application_No,
          Fund: res.data.data?.Fund,
          Scheme: res.data.data?.Scheme,
          Scheme_Name: res.data.data?.Scheme_Name,
          Target_Scheme_Name: "",
          Source_Scheme_Name: "",
          Amt: res.data.data.Amt,
          Status_Desc: res.data.data?.Status_Desc,
          Status_code: res.data.data?.Status_code,
          Folio: res.data.data?.Folio,
          Platform: "Web",
          transaction_type: "Additional",
          iin: useProfileData?.iin,
          Pan: useProfileData?.pan,
          Gpan: useProfileData?.gpan,
          Joint1pan: useProfileData?.Joint1pan,
          Joint2pan: useProfileData?.Joint2pan,
          name: useProfileData?.name,
        };
        refreshState()

        saveNseTransaction(nseDataForSave, headerState).then((res) => {
        });
        if (res.data?.data?.Paymentlink) {
          window.open(res.data?.data?.Paymentlink);
        }
      } else {
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
      }
      setLoader('none')

    });
  }

  const changeMandateHandler = (e) => {
    if (e.target.value) {
      setMandateUrn(e.target.value)
      let urnValue = JSON.parse(e.target.value)
      setTempMandateUrn(urnValue)

    }
  }
  const checkMandate = () => {
    if (useProfileData?.email && mandateListData?.length === 0) {
      setIsMandateAvailable(true)
      setHideMandate('d-none')
      setPaymentMode("")
    }
  }
  const dateAny = (id) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  }

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} scrollable={true} className="animate__animated animate__zoomIn animate__fast">
        <Modal.Header closeButton>
          <Modal.Title>Addtitional Purchase</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className='text-red'>{data.name}</h5>
          {/* <div className="col-3 bg-light-red text-center rounded">
            <p className='py-1 '>{data.assettype}</p>
          </div> */}
          <p className='fs-13 text-red'>{data.scheme}  ({data.folio})</p>
          <Form className="portfolio">
            <Form.Group className="mb-3" controlId="formBasicText">
              <Form.Label>Enter Amount </Form.Label>
              <Form.Control type="text" placeholder="Enter Amount" className="border-0 single-drop" value={amount} onChange={amountHandler} />
              <small className="text-label float-right">
                      Min Amount : 5000
                    </small>
            </Form.Group>
           
            <Form.Group className="mb-3" controlId="formBasicDate">
              <Form.Label>Select Payment Mode </Form.Label>
              <Form.Select aria-label="Default select example" className="border-0 single-drop" value={paymentMode}
                onChange={changePaymentModeHandler}>
                <option value="">Select Payment Mode</option>
                <option value={"OL"}>Net Banking</option>
                <option value={"UPI"}>UPI</option>
                <option value={"TR"}>RTGS/NEFT</option>
                <option value={"M"}>Debit Mandate</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className={`mb-3 ${hideBank}`} controlId="formBasicDate">
              <Form.Label>Select Bank</Form.Label>
              <Form.Select aria-label="Default select example" className="border-0 single-drop" value={bankName}
                onChange={changBankHandler} disabled={bankLoder}>
                <option value={""}>Select Bank</option>
                {usersBankDetail &&
                  usersBankDetail.map((el) => (
                    el.STATUS ==="Activated" ?  <option
                      value={JSON.stringify({
                        AC_NO: el.AC_NO,
                        BANK_CODE: el.BANK_CODE,
                        IFSC_CODE: el.IFSC_CODE,
                        BRANCH_NAME: el.BRANCH_NAME,
                        BANK_NAME:el.BANK_NAME
                      })}
                    >{`${el.BANK_NAME} | Ac No- ${el.AC_NO}`}</option>:""
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className={`mb-3 ${hideMandate}`} controlId="formBasicDate">
              <Form.Label>Select Mandate</Form.Label>
              <Form.Select aria-label="Default select example" className="border-0 single-drop" value={mandateUrn} onChange={changeMandateHandler} disabled={mandateLoder} onClick={checkMandate}>
                <option value={""}>Select Mandate</option>
                {mandateListData.length > 0 && mandateListData.map((data) => (
                  <option value={JSON.stringify({ UMRN_NO: data.UMRN_NO, BANK_CODE: data.BANK_CODE, ACCOUNT_NO: data.ACCOUNT_NO, MANDATE_DATE: data.MANDATE_DATE, AC_TYPE: data.AC_TYPE })}>{`${data.BANK_NAME} | Ac No-  ${data.ACCOUNT_NO} | Amount- ${data.AMOUNT > 0 ? data.AMOUNT :"0"} `}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className={`mb-3 ${hideRTGS}`} controlId="formBasicText">
              <Form.Label>UTR No</Form.Label>
              <Form.Control type="text" className="border-0 single-drop" placeholder="Enter UTR " value={utrNo}
                onChange={(e) => setUtrNo(e.target.value)} />
            </Form.Group>
            <Form.Group className={`mb-3 ${hideRTGS}`} controlId="formBasicDate">
              <Form.Label>Transfer Date</Form.Label>
              <Form.Control type="date" className="border-0 single-drop" value={transferDate}
                onChange={(e) => setTransferDate(e.target.value)}  id="anyWhere5" max={futureDateHide}
                onClick={() => dateAny("anyWhere5")}/>
            </Form.Group>
            <Form.Group className={`mb-3 d-flex ${hideImidiatePayment}`} controlId="formBasicRadio">

              <Form.Check
                type="radio"
                label="Immediate Payment"
                name="formHorizont"
                id="ImmediatePayment"
                className="pe-3 fs-sm-14"
                value={"Y"}
                checked={imediateOrEmail == "Y"}
                onChange={(e) => setImediateOrEmail(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="Link On Email"
                name="formHorizont"
                id="Link On Email"
                className="pe-3 fs-sm-14"
                value={"N"}
                checked={imediateOrEmail == "N"}
                onChange={(e) => setImediateOrEmail(e.target.value)}
              />
            </Form.Group>
            {paymentMode==="TR"? <div className="col mt-3">
              <ul className="fs-12 mb-0 ps-3 list-unstyled">
                <li>
                  <h6 className="text-red fs-13">Note :-</h6>
                </li>
                <li className="text-rtg"> <span className="text-red">*</span>Beneficiary Name<span className="text-black">-  NSE Clearing – New Mutual Fund Platform</span></li>
                <li className="text-rtg"> <span className="text-red">*</span>Beneficiary Bank Name <span className="text-black">- HDFC Bank Ltd</span></li>
                <li className="text-rtg"><span className="text-red">*</span>Beneficiary Branch Name<span className="text-black">- Fort, Mumbai</span></li>
                <li className="text-rtg"><span className="text-red">*</span>Virtual Account No<span className="text-black">-  ({`NSEMF221399${useProfileData?.iin ? useProfileData?.iin:""}`})</span></li>
                <li className="text-rtg"><span className="text-red">*</span>Account Type<span className="text-black">- Current Account</span></li>
                <li className="text-rtg"><span className="text-red">*</span>IFSC Code (For RTGS / NEFT Transfers)<span className="text-black">- HDFC0000060</span></li>
              </ul>
            </div>:""}
           
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="danger" onClick={orderAdditional}>
            Order
          </Button>
        </Modal.Footer>
      </Modal>
      <TransactSuccess
        show={confirmShow}
        setShow={setConfirmShow}
        confirmData={confirmationData}
        confirmationDataArr={confirmationDataArr}
      />
      <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
      <ConfirmationMandatePopup
        show={isMandateAvailable}
        setShow={setIsMandateAvailable}
        profileDetail={{name:useProfileData?.name, iin:useProfileData?.iin,pan:useProfileData?.pan,gpan:useProfileData?.gpan}}
      />
    </>
  )
}
export default Additional_Purchase_Popup;