import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { firebaseConfig } from "./config"
import axios from "axios";
import { storeDeviceToken } from "../apisMethods";


export const webApp = initializeApp(firebaseConfig);

export const messaging = getMessaging(webApp);

export const setupNotification = async (notificationList, setNotification) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission == "granted") {
      const token = await getToken(messaging);
      let tokenuser = localStorage.getItem("token");
      const header = {
        headers:
          { Authorization: `Bearer ${tokenuser}` }
      };

      storeDeviceToken(  {  iosTOkens: [], androidTOkens: [], webTOkens: [token], platform: [], }, header ).then((res) => {
          console.log(res.data);
        });

        let listData = JSON.parse(localStorage.getItem("notification"));
        if(listData){
        setNotification(listData)
        }else{
          setNotification([])
        }



      onMessage(messaging, (payload) => {
        let list = JSON.parse(localStorage.getItem("notification"));
        console.log("list",list);
        if (list) {
          list.unshift({...payload.notification,status:true});
          console.log("list first",list)
        } else {
          list = [];
          list.unshift({...payload.notification,status:true});
          console.log("list second",list)
        }

        setNotification(list)
        console.log("third list",list)
        localStorage.setItem("notification", JSON.stringify(list));
      });

    } else {
      console.log("Notification permission denied.");
    }
  } catch (error) {
    console.error("Error setting up notifications:", error);
  }
};

// const removeDupliacate = (data,setNotification)=>{
//   const uniqueData = [];
// const names = new Set();

// data.forEach(item => {
//   if (!names.has(item.body)) {
//     names.add(item.body);
//     uniqueData.push(item);
//   }
// });

// setNotification(uniqueData)
// }
