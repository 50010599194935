import React, { useState, useEffect, useContext } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link, useNavigate } from "react-router-dom";
import AnotherScheme from "../components/looking-for-another-scheme";
import OderSucess from "../components/oder-sucess";
import { getAmcList, userProfile, getMandateList, simplySaveNseSchemes, usersBank, folioViaAmc, newPurchase, saveNseTransaction } from "../apisMethods";
import Form from "react-bootstrap/Form";
import { notify } from '../pages/toastCreater';
import TransactSuccess from "../components/transact-success";
import SWW from "../components/something-went-wrong";
import { globalInfo } from "../App";
import { disableFutureDate } from "../currentMonth/FinancialYear";
import ConfirmationMandatePopup from "../components/mandateConfirmationPopup";

const SimplySave = () => {
  const navigate = useNavigate()
  const { setLoader } = useContext(globalInfo)
  const [anotherschemeShow, setAnotherSchemeShow] = useState(false)
  const [show, setShow] = useState([])
  const [confirmShow, setConfirmShow] = useState(false)
  const [userProfileData, setUserProfileData] = useState([])
  const [headerState, setHeaderState] = useState({})
  const [profileName, setProfileName] = useState('')
  const [tempProfileName, setTempProfileName] = useState({})
  const [recomendedSchemes, setRecomendedSchemes] = useState([])
  const [amount, setAmount] = useState('')
  const [paymentMode, setPaymentMode] = useState('')
  const [hideBank, setHideBank] = useState('d-none')
  const [hideImidiatePayment, setHideImidiatePayment] = useState('d-none')
  const [hideRTGS, setHideRTGS] = useState('d-none')
  const [hideMandate, setHideMandate] = useState('d-none')
  const [mandateUrn, setMandateUrn] = useState('')
  const [bankName, setBankName] = useState('')
  const [tempMandateUrn, setTempMandateUrn] = useState('')
  const [tempBankName, setTempBankName] = useState({})
  const [transferDate, setTransferDate] = useState('')
  const [utrNo, setUtrNo] = useState('')
  const [mandateListData, setMandateListData] = useState([])
  const [usersBankDetail, setUsersBankDetail] = useState([])
  const [folioLoader, setFolioLoader] = useState(false)
  const [folio, setFolio] = useState('')
  const [tempFolio, setTempFolio] = useState({})
  const [folioList, setFolioList] = useState([])
  const [amc, setAmc] = useState('')
  const [imediateOrEmail, setImediateOrEmail] = useState('')
  const [confirmationDataArr, setConfirmationDataArr] = useState([])
  const [confirmationData, setConfirmationData] = useState({})
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [disableDate, setDisabledDate] = useState('')
  const [isMandateAvailable, setIsMandateAvailable] = useState(false)





  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers)
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      if (userdata?.pan && !userdata?.iin) {
        navigate("/dashboard/Profile-creation");
      } else {
        userProfileCall(userdata?.pan, headers)
        recomendedSchemeCall(headers)
      }
    } else if (!token) {
      navigate("/");
    }
    else if (!userdata?.pan && token) {
      navigate("/dashboard/Profile-creation");
    }
    let dates = disableFutureDate()
    setDisabledDate(dates)
  }, []);

  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if (!res.data) {
        setUserProfileData([])
      } else {
        setUserProfileData(res.data?.data)
      }
    })
  }
  const recomendedSchemeCall = (header) => {
    simplySaveNseSchemes(header).then(res => {
      if (!res.data) {
        setRecomendedSchemes([])
      } else {
        setRecomendedSchemes(res.data?.data)
      }
    })
  }
  const refreshStateForProfile = () => {
    setFolio('')
    setTempFolio({})
    setAmount('')
    setBankName('')
    setTempBankName({})
    setFolioList([])
    setUtrNo('')
    setTransferDate('')
    setMandateListData([])
    setMandateUrn('')
    setTempMandateUrn({})
    setUsersBankDetail([])
    setPaymentMode('')
    setHideBank('d-none')
    setHideMandate('d-none')
    setHideRTGS('d-none')
    setHideImidiatePayment('d-none')
  }

  const profileChangeHandler = (e) => {
    if (e.target.value !== "") {
      refreshStateForProfile()
      // refreshForProfile();
      let reqData = JSON.parse(e.target.value);
      setProfileName(e.target.value);
      setTempProfileName(reqData);
      usersBankCall(reqData.IIN)
      amcChangeHandler(reqData)
      // getMandateListCall(reqData.IIN)
    }
  };

  const amcChangeHandler = (reqData) => {
    setFolioLoader(true);
    setFolio('')
    let value = recomendedSchemes[0]?.AMC_CODE;
    if (value) {
      setAmc(value);
      if (value) {
        // setFolioLoading(true);
        let joint1stPan = "";
        let joint2ndPan = "";

        if (reqData.JOINT1_PAN) {
          joint1stPan = reqData.JOINT1_PAN;
        } else if (reqData.JOINT1_VALIDPAN.length > 1) {
          joint1stPan = reqData.JOINT1_VALIDPAN;
        } else {
          joint1stPan = "";
        }
        if (reqData.JOINT2_PAN) {
          joint2ndPan = reqData.JOINT2_PAN;
        } else if (reqData.JOINT2_VALIDPAN.length == 10) {
          joint2ndPan = reqData.JOINT2_VALIDPAN;
        } else {
          joint2ndPan = "";
        }

        const reqBody = {
          investor_pan: reqData.pan,
          jh1_pan: joint1stPan,
          jh2_pan: joint2ndPan,
          amccode: value,
          gpan: reqData?.gpan ? reqData?.gpan:"",
        };
        folioViaAmc(reqBody, headerState).then((res) => {
          if (!res.data) {
            // notify("error", res.error.response.data.msg);
            // setFolioLoading(false);
            setFolioList([]);
            setFolioLoader(false);
          } else {
            setFolioList(res.data?.data);
            setFolioLoader(false);
          }
        });
      } else {
        setFolioList([]);
      }
    }
  };
  const changeFolioHandler = (e) => {
    if (e.target.value) {
      // setSchemeLoader(true);
      let jsonData = JSON.parse(e.target.value);
      setFolio(e.target.value);
      setTempFolio(jsonData);

    }
  };
  const usersBankCall = (iin) => {
    setBankName('')
    setTempBankName({})
    usersBank({ iin }, headerState).then(res => {
      if (!res.data) {
        setUsersBankDetail([])
      } else {
        setUsersBankDetail(res.data?.data)
      }
    })
  }
  const amountHandler = (e) => {
    const inputValue = e.target.value;
    if (inputValue < 10000000) {
      const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters
      setAmount(sanitizedValue);
    } else if (!isNaN(inputValue)) {
      setAmount(10000000);
    }
  };
  const changePaymentModeHandler = (e) => {
    let value = e.target.value
    setPaymentMode(e.target.value)
    if (value === "OL" || value === "UPI") {
      setHideRTGS('d-none')
      setHideBank("")
      setHideMandate('d-none')
      setHideImidiatePayment('')
      setMandateUrn('')
      setMandateUrn("")
      setBankName("")
      setTempBankName({})
      setTempMandateUrn({})
      setTransferDate('')
      setUtrNo('')
    } else if (value === "TR") {
      setHideRTGS('')
      setHideBank("")
      setHideMandate('d-none')
      setHideImidiatePayment('d-none')
      setMandateUrn('')
      setTempMandateUrn({})
      setBankName("")
      setTempBankName({})
    } else if (value === "M") {
      setTransferDate('')
      setUtrNo('')
      setHideBank("d-none")
      setMandateUrn("")
      setTempMandateUrn({})
      setBankName("")
      setTempBankName({})
      setHideMandate('')
      setHideRTGS('d-none')
      setHideImidiatePayment('d-none')
      getMandateListCall()

    }
  };
  const getMandateListCall = () => {
    getMandateList({ iin: tempProfileName.IIN }, headerState).then(res => {
      if (!res.data) {
        setMandateListData([])
      } else {
        setMandateListData(res.data?.data)
      }
    })
  }

  const changeMandateHandler = (e) => {
    if (e.target.value) {
      setMandateUrn(e.target.value)
      let urnValue = JSON.parse(e.target.value)
      setTempMandateUrn(urnValue)

    }
  }
  const changBankHandler = (e) => {
    if (e.target.value) {
      let bankDetailObj = JSON.parse(e.target.value)
      setBankName(e.target.value)
      setTempBankName(bankDetailObj)
    }
  }

  const dateAny = (id) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  }

  function convertMonthNumberToName(monthNumber) {
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    // Subtract 1 from monthNumber since JavaScript months are zero-based (January is 0)
    const monthIndex = monthNumber - 1;

    // Check if monthIndex is within the valid range
    if (monthIndex >= 0 && monthIndex < monthNames.length) {
      return monthNames[monthIndex];
    } else {
      return "Invalid month";
    }
  }

  const refreshState = () => {
    setProfileName('')
    setTempProfileName({})
    setFolio('')
    setTempFolio({})
    setAmount('')
    setBankName('')
    setTempBankName({})
    setFolioList([])
    setUtrNo('')
    setTransferDate('')
    setMandateListData([])
    setMandateUrn('')
    setTempMandateUrn({})
    setUsersBankDetail([])
    setPaymentMode('')
    setHideBank('d-none')
    setHideMandate('d-none')
    setHideRTGS('d-none')
    setHideImidiatePayment('d-none')
  }
  const orderSimplySave = (e) => {
    e.preventDefault()
    if (!amount || !profileName || !paymentMode) {
      notify("error", "All fields required.");
      return;
    }
    if (amount < 5000) {
      notify("warn", "amount should not be less than 5000");
      return;
    }

    if (!bankName && paymentMode !== "M") {
      notify("error", "Please select bank name");
      return;
    }
    if (paymentMode === "M" && !mandateUrn) {
      notify("error", "Please select mandate ");
      return;
    }
    let arrayDate = []
    if (transferDate) {
      arrayDate = transferDate.split('-')
    }
    setLoader("block")
    let reqData = {
      email: tempProfileName.email,
      tax_status: tempProfileName.taxStatus,
      sub_trxn_type: "N",
      trxn_acceptance: "ALL",
      payment_mode: paymentMode,
      instrm_amount: amount,
      debit_amount_type: "",
      Return_paymnt_flag: paymentMode === "OL" || paymentMode === "UPI" ? imediateOrEmail : "N",
      Client_callback_url: "API URL",
      ach_exist: "Y",
      amc: amc,
      product_code: recomendedSchemes[0]?.PRODUCT_CODE,
      reinvest: recomendedSchemes[0]?.REINVEST_TAG,
      amount: amount,
      input_ref_no: "",
      perpetual_flag: "N",
      insurance_enabled: "N",
      instrm_date: "",
      rtgs_code: paymentMode === "TR" ? tempBankName.IFSC_CODE : "",
      transfer_date: transferDate ? arrayDate[2] + "-" + convertMonthNumberToName(arrayDate[1]) + "-" + arrayDate[0] : "",
      utr_no: utrNo,
      umrn: paymentMode === "M" ? tempMandateUrn.UMRN_NO : "",
      folio: tempFolio.folio,
      iin: tempProfileName.IIN,
      accountNo: paymentMode === "M" ? tempMandateUrn.ACCOUNT_NO : tempBankName.AC_NO,
      bank_code: paymentMode === "M" ? tempMandateUrn.BANK_CODE : tempBankName.BANK_CODE,
      ifsc_code: paymentMode === "M" ? "" : tempBankName.IFSC_CODE,
      branch: paymentMode === "M" ? "" : tempBankName.BRANCH_NAME,
      holder_name: tempProfileName.name,
    };
    newPurchase(reqData, headerState).then((res) => {
      if (res.data?.status === 200) {
        setConfirmationData(res.data?.data);
        setConfirmShow(true);
        setShow(false);
        let nseData = {
          Unique_No: res.data.data.Unique_No,
          Trxn_No: res.data.data.Trxn_No,
          Application_No: res.data.data.Application_No,
          Fund: res.data.data.Fund,
          Scheme: res.data.data.Scheme,
          Scheme_Name: res.data.data.Scheme_Name,
          Target_Scheme_Name: "",
          Source_Scheme_Name: "",
          Amt: res.data.data.Amt,
          Status_Desc: res.data.data.Status_Desc,
          Status_code: res.data.data.Status_code,
          Folio: res.data.data.Folio,
          Platform: "Web",
          transaction_type: "SimplySave",
          iin: tempProfileName.IIN,
          Pan: tempProfileName.pan,
          Gpan: tempProfileName.gpan,
          Joint1pan: tempProfileName.JOINT1_PAN,
          Joint2pan: tempProfileName.JOINT2_PAN,
          name: tempProfileName.name,
        };
        refreshState()
        setProfileName("")
        setTempProfileName({})
        saveNseTransaction(nseData, headerState).then((res) => {
        });

        if (res.data.data.Paymentlink) {
          window.open(res.data.data.Paymentlink);
        }
      } else {
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);
      }
      setLoader("none")

    });
  }
  const checkMandate = () => {
    if (profileName && mandateListData.length === 0) {
      setIsMandateAvailable(true)
      setHideMandate("d-none")
      setPaymentMode("");
    }
  }
  return (
    <>
      <div className="wrapper">
        <div className="report px-lg-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb ps-3">
              <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Simply Save</li>
            </ol>
          </nav>
        </div>

        <div className="container-fluid pt-3">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="shadow-custom bg-light-red mb-4 pe-3">
                <Form className="p-4 pb-0 text-start simply-save" onSubmit={orderSimplySave}>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>
                        Select Profile <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={profileName}
                        onChange={profileChangeHandler}
                        required
                      >
                        <option value="">Select Profile</option>
                        {userProfileData.length > 0
                          ? userProfileData.map((el) => (
                            <option
                              value={JSON.stringify({
                                email: el.EMAIL,
                                taxStatus: el.TAX_STATUS,
                                IIN: el.IIN,
                                name: el.NAME,
                                pan: el.PAN,
                                gpan: el?.GUARD_PAN,
                                jtname1: el.JOINT_NAME1,
                                jtname2: el.JOINT_NAME2,
                                nominee: el.NOM_NAME,
                                JOINT1_PAN: el.JOINT1_PAN,
                                JOINT2_PAN: el.JOINT2_PAN,
                                JOINT1_VALIDPAN: el.JOINT1_VALIDPAN,
                                JOINT2_VALIDPAN: el.JOINT2_VALIDPAN,
                                bankName: el.BANK_NAME,
                                bankCode: el.BANK_CODE,
                                accountNo: el.AC_NO,
                                accountType: el.AC_TYPE,
                                ifscCode: el.IFSC_CODE,
                                branchName: el.BRANCH_NAME,
                                branchAddress: el.BRANCH_ADDRESS1,
                              })}
                              key={el.PAN}
                            >
                              {`${el.NAME} ${el.JOINT_NAME1 && '|'} ${el.JOINT_NAME1}`}
                            </option>
                          ))
                          : null}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} sm={8} controlId="formGridEmail">
                      <Form.Label>Recommended Scheme <span className="text-red">*</span></Form.Label>
                      <Form.Control type="text" className="bg-white bg-c" value={recomendedSchemes[0]?.PRODUCT_LONG_NAME}  />
                      {/* <Form.Text className="text-end d-block">
                      <Link className="text-red" onClick={() => setAnotherSchemeShow(true)}>Looking for another scheme?</Link> 
                      </Form.Text> */}
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group className="col-lg-4 col-12" controlId="formGridPassword">
                      <Form.Label>
                        Select Folio <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        className="bg-c"
                        value={folio}
                        onChange={changeFolioHandler}
                        disabled={folioLoader}
                      >
                        <option value="">New Folio</option>
                        {folioList &&
                          folioList.map((el) => (
                            <option
                              value={JSON.stringify({
                                folio: el.folio,
                                accord_schemecode: el.accord_schemecode,
                                rta: el.rta,
                              })}
                              key={el.PAN}
                            >
                              {el.folio}
                            </option>
                          ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="col-lg-4 col-12" controlId="formGridEmail">
                      <Form.Label>Enter Investment Amount <span className="text-red">*</span></Form.Label>
                      <Form.Control type="text" className="bg-white bg-c" value={amount} onChange={amountHandler} placeholder="Enter Amount" />
                      <small className="text-label float-right">
                        Min Amount : 5000
                      </small>
                    </Form.Group>

                    <Form.Group className="col-lg-4 col-12" controlId="formGridPassword">
                      <Form.Label>
                        Select Payment Mode
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select className="bg-c" value={paymentMode} onChange={changePaymentModeHandler}>
                        <option value="">Select Payment Mode</option>
                        <option value={"OL"}>Net Banking</option>
                        <option value={"UPI"}>UPI</option>
                        <option value={"TR"}>RTGS/NEFT</option>
                        <option value={"M"}>Debit Mandate</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group className={`col-lg-4 col-12 ${hideBank}`} controlId="formGridPassword">
                      <Form.Label>
                        Select Bank <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select className="bg-c" value={bankName} onChange={changBankHandler}>
                        <option value="">Select Bank</option>
                        {usersBankDetail && usersBankDetail.map((el) => (
                             el.STATUS ==="Activated" ? <option value={JSON.stringify({ AC_NO: el.AC_NO, BANK_CODE: el.BANK_CODE, IFSC_CODE: el.IFSC_CODE, BRANCH_NAME: el.BRANCH_NAME, })}>{`${el.BANK_NAME} | Ac No- ${el.AC_NO}`}</option>:""

                        ))}
                      </Form.Select>
                    </Form.Group>

                    <div className={`col-md-4 ${hideMandate}`}>
                      <Form.Group>
                        <Form.Label>
                          Select Mandate <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select className="bg-c" value={mandateUrn} onChange={changeMandateHandler} onClick={checkMandate}>
                          <option value={""}>Select Mandate</option>
                          {mandateListData.length > 0 && mandateListData.map((data) => (
                            <option value={JSON.stringify({ UMRN_NO: data.UMRN_NO, BANK_CODE: data.BANK_CODE, ACCOUNT_NO: data.ACCOUNT_NO, MANDATE_DATE: data.MANDATE_DATE, AC_TYPE: data.AC_TYPE })}>{`${data.BANK_NAME} | Ac No-  ${data.ACCOUNT_NO} |  Amount- ${data?.AMOUNT > 0 ? data?.AMOUNT : "0"} `}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className={`col-md-4 ${hideRTGS}`} >
                      <Form.Group>
                        <Form.Label>
                          UTR No.
                        </Form.Label>
                        <Form.Control
                          className="bg-c"
                          type="text"
                          placeholder="Enter UTR No"
                          value={utrNo}
                          onChange={(e) => {
                            if (e.target.value.length < 30) {
                              setUtrNo(e.target.value)
                            }
                          }}
                        />
                      </Form.Group>
                    </div>

                    <Form.Group className={`col-lg-4 col-12 ${hideRTGS}`} controlId="formGridEmail">
                      <Form.Label>Transfer Date <span className="text-red">*</span></Form.Label>
                      <Form.Control type="date" className="bg-white bg-c" value={transferDate}
                        id="transferdate"
                        max={disableDate}
                        onClick={() => dateAny("transferdate")}
                        onChange={(e) => setTransferDate(e.target.value)} />
                    </Form.Group>
                  </Row>

                  <Form.Group className={`mb-3 d-flex ${hideImidiatePayment}`} controlId="formBasicRadio">
                    <Form.Check
                      type="radio"
                      label="Immediate Payment"
                      name="formHorizont"
                      id="ImmediatePayment"
                      className="pe-3 fs-sm-14"
                      value={"Y"}
                      checked={imediateOrEmail == "Y"}
                      onChange={(e) => setImediateOrEmail(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Link On Email"
                      name="formHorizont"
                      id="Link On Email"
                      className="pe-3 fs-sm-14"
                      value={"N"}
                      checked={imediateOrEmail == "N"}
                      onChange={(e) => setImediateOrEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Row className="pb-3">
                    <Col className="text-end">
                      <button type="submit" className="btn-custom" >Order Now</button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <AnotherScheme show={anotherschemeShow} setShow={setAnotherSchemeShow} />
        {/* <OderSucess show={odersucessShow} setShow={setOderSucessShow} /> */}

        <TransactSuccess
          show={confirmShow}
          setShow={setConfirmShow}
          confirmData={confirmationData}
          confirmationDataArr={confirmationDataArr}
        />
        <SWW show={errorConfirmationShow} setShows={setErrorConfirmationShow} errorMsg={errorMsg} />
        <ConfirmationMandatePopup
          show={isMandateAvailable}
          setShow={setIsMandateAvailable}
          profileDetail={{ name: tempProfileName?.name, iin: tempProfileName?.IIN,pan:tempProfileName?.pan,gpan:tempProfileName?.gpan }}
        /> 
      </div>
    </>
  )
}
export default SimplySave