import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import { BsArrowLeft } from "react-icons/bs";
import { userProfile, getMandateList, folioViaAmc, multiplePurchase, saveNseTransaction, usersBank } from "../apisMethods";
// import Cart_Delete from './delete-confirmation';
import SWW from "./something-went-wrong";
import TransactSuccess from "./transact-success";
import { globalInfo } from "../App";
import { notify } from '../pages/toastCreater';
import { disableFutureDate, formatDate } from '../currentMonth/FinancialYear';
import Cart_Delete from './delete-confirmation';
import ConfirmationMandatePopup from './mandateConfirmationPopup';

const LumpsumForm = ({ show, setShow, rightSchemes }) => {
  const { setLoader } = useContext(globalInfo)
  const [confirmShow, setConfirmShow] = useState(false);
  const [errorConfirmationShow, setErrorConfirmationShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState('')
  const [confirmationData, setConfirmationData] = useState({});
  const [confirmationDataArr, setConfirmationDataArr] = useState([]);

  const [userProfileData, setUserProfileData] = useState([])
  const [headerState, setHeaderState] = useState({})
  const [profileName, setProfileName] = useState('')
  const [tempProfileName, setTempProfileName] = useState({})
  const [folioList, setFolioList] = useState([])

  const [Purchase, setPurchase] = useState("d-none");
  const [Confirmp, setConfirmp] = useState("d-none");
  const [Detail, setDetail] = useState("d-block");
  const [folioLoader, setFolioLoader] = useState(false)
  const [totalInvestment, setTotalInvestment] = useState('')

  const [paymentMode, setPaymentMode] = useState('');
  const [hideRTGS, setHideRTGS] = useState('d-none');
  const [hideMandate, setHideMandate] = useState('d-none');
  const [hideImidiatePayment, setHideImidiatePayment] = useState('d-none');
  const [imediateOrEmail, setImediateOrEmail] = useState("Y");
  const [usersBankDetail, setUsersBankDetail] = useState([]);
  const [bankName, setBankName] = useState('');
  const [hideBank, setHideBank] = useState('d-none');
  const [tempBankName, setTempBankName] = useState({});
  const [mandateListData, setMandateListData] = useState([]);
  const [mandateUrn, setMandateUrn] = useState('');
  const [tempMandateUrn, setTempMandateUrn] = useState({});
  const [utrNo, setUtrNo] = useState("")
  const [transferDate, setTransferDate] = useState('')
  const [futureDisabledDate, setFutureDisabledDate] = useState('')
  const [isMandateAvailable, setIsMandateAvailable] = useState(false)


  useEffect(() => {
    const token = localStorage.getItem("token");
    const headers = { headers: { Authorization: `Bearer ${token}` } };
    setHeaderState(headers)
    const userdata = JSON.parse(localStorage.getItem("user"));
    if (userdata?.pan) {
      userProfileCall(userdata?.pan, headers)
    }
    let future = disableFutureDate()
    setFutureDisabledDate(future)

  }, []);

  const refreshForProfile = () => {
    setFolioList([]);
    setMandateUrn('')
    setUtrNo('')
    setTransferDate('')
    setPaymentMode('')
    setBankName('')
    setTempBankName({})
    setMandateListData([])
    setTempProfileName({})
    setProfileName('')
  };
  useEffect(() => {
    setConfirmp('d-none')
    setPurchase('d-none')
    setDetail('d-block')
    refreshForProfile()
    setHideBank("d-none")
    setHideImidiatePayment("d-none")
    setHideRTGS("d-none")
  }, [show])


  const userProfileCall = (pan, header) => {
    userProfile({ pan }, header).then(res => {
      if (!res.data) {
        setUserProfileData([])
      } else {
        setUserProfileData(res.data?.data)
      }
    })
  }
  const profileChangeHandler = (e) => {
    if (e.target.value !== "") {
      // refreshForProfile();
      setLoader("block")
      let reqData = JSON.parse(e.target.value);
      setProfileName(e.target.value);
      setTempProfileName(reqData);
      // getMandateListCall(reqData.IIN)
      amcChangeHandler(reqData)
      usersBankCall(reqData.IIN)
    }
  };
  const usersBankCall = (iin) => {
    usersBank({ iin }, headerState).then(res => {
      if (!res.data) {
        setUsersBankDetail([])
      } else {
        setUsersBankDetail(res.data?.data)
      }
    })
  }
  const amcChangeHandler = async (reqData) => {
    let arr = []
    console.log("rightSchemes",rightSchemes);
    for (let i = 0; i < rightSchemes.length; i++) {
      let value = rightSchemes[i]?.AMC_CODE;
      if (value) {
        setFolioLoader(true);
        let joint1stPan = "";
        let joint2ndPan = "";

        if (reqData.JOINT1_PAN) {
          joint1stPan = reqData.JOINT1_PAN;
        } else if (reqData.JOINT1_VALIDPAN.length > 1) {
          joint1stPan = reqData.JOINT1_VALIDPAN;
        } else {
          joint1stPan = "";
        }
        if (reqData.JOINT2_PAN) {
          joint2ndPan = reqData.JOINT2_PAN;
        } else if (reqData.JOINT2_VALIDPAN.length == 10) {
          joint2ndPan = reqData.JOINT2_VALIDPAN;
        } else {
          joint2ndPan = "";
        }

        const reqBody = {
          investor_pan: reqData.pan,
          jh1_pan: joint1stPan,
          jh2_pan: joint2ndPan,
          amccode: value,
          gpan: reqData?.gpan ? reqData?.gpan:"",
        };
        const res = await folioViaAmc(reqBody, headerState)
        if (!res.data) {
          // notify("error", res.error.response.data.msg);
          arr.push({
            folioArr: [], scheme: rightSchemes[i],

            amc: rightSchemes[i].AMC_CODE,
            profileName: reqData.name,
            folio: "",
            product_code: rightSchemes[i]?.PRODUCT_CODE,
            reinvest: rightSchemes[i]?.REINVEST_TAG,

            amount: "",
            // sip_period_day: fromDateParts[2],
            input_ref_no: "",
            perpetual_flag: "N",
            schemeName: rightSchemes[i]?.PRODUCT_LONG_NAME,
          })
        } else {
          arr.push({
            folioArr: res.data?.data, scheme: rightSchemes[i],

            amc: rightSchemes[i].AMC_CODE,
            profileName: reqData.name,
            folio: "",
            product_code: rightSchemes[i]?.PRODUCT_CODE,
            reinvest: rightSchemes[i]?.REINVEST_TAG,
            amount: "",
            input_ref_no: "",
            perpetual_flag: "N",
            schemeName: rightSchemes[i]?.PRODUCT_LONG_NAME,
          })
        }


      } else {
        setFolioList([]);
      }
      setFolioLoader(false);
    }
    setFolioList(arr)
    setLoader("none")

  };

  const changeFolioHandler = (e, i) => {
    if (e.target.value) {
      const updatedData = [...folioList];
      updatedData[i].folio = e.target.value;
      setFolioList(updatedData);
    } else {
      const updatedData = [...folioList];
      updatedData[i].folio = "";
      setFolioList(updatedData);
    }
  };

  const amountHandler = (e, i) => {
    const inputValue = e.target.value;
    if (inputValue < 10000000) {
      const sanitizedValue = inputValue.replace(/^0+/, ''); // Remove non-numeric characters

      const updatedData = [...folioList];
      updatedData[i].amount = sanitizedValue;
      setFolioList(updatedData);

    } else if (!isNaN(inputValue)) {
      const updatedData = [...folioList];
      updatedData[i].amount = 10000000;
      setFolioList(updatedData);
    }
  };
  const continueFromProfile = () => {
    if (!profileName) {
      notify("error", "Please select your profile")
      return
    }
    let arrayCount = folioList.length
    for (let i = 0; i < arrayCount; i++) {

      if (!folioList[i].amount) {
        notify("error", "Please enter amount")
        return
      }
      if (folioList[i].amount < 5000) {
        notify("error", "Amount should be greater than min amount")
        return
      }
    }
    const totalAmount = folioList.reduce((accumulator, currentObject) => {
      return accumulator + Number(currentObject.amount);
    }, 0);
    setTotalInvestment(totalAmount)
    setConfirmp("d-block")
    setDetail("d-none")
  }
  const continueFromDetail = () => {
    setPurchase("d-block")
    setConfirmp("d-none")
  }
  const edit = () => {
    setConfirmp("d-none")
    setDetail("d-block")
    setPurchase("d-none")
  }

  const changePaymentModeHandler = (e) => {
    let value = e.target.value
    setPaymentMode(e.target.value)
    if (value === "OL" || value === "UPI") {
      setHideRTGS('d-none')
      setHideBank("")
      setHideMandate('d-none')
      setHideImidiatePayment('')
      setMandateUrn('')
      setMandateUrn("")
      setBankName("")
      setTempBankName({})
      setTempMandateUrn({})
      setTransferDate('')
      setUtrNo('')
    } else if (value === "TR") {
      setHideRTGS('')
      setHideBank("")
      setHideMandate('d-none')
      setHideImidiatePayment('d-none')
      setMandateUrn('')
      setTempMandateUrn({})
      setBankName("")
      setTempBankName({})
    } else if (value === "M") {
      setTransferDate('')
      setUtrNo('')
      setHideBank("d-none")
      setMandateUrn("")
      setTempMandateUrn({})
      setBankName("")
      setTempBankName({})
      setHideMandate('')
      setHideRTGS('d-none')
      setHideImidiatePayment('d-none')
      getMandateListCall()

    }
  }
  const getMandateListCall = () => {
    getMandateList({ iin: tempProfileName.IIN }, headerState).then(res => {
      if (!res.data) {
        setMandateListData([])
      } else {
        setMandateListData(res.data?.data)
      }
    })
  }

  const changBankHandler = (e) => {
    if (e.target.value) {
      let bankDetailObj = JSON.parse(e.target.value)
      setBankName(e.target.value)
      setTempBankName(bankDetailObj)
    }
  }
  const changeMandateHandler = (e) => {
    if (e.target.value) {
      setMandateUrn(e.target.value)
      let urnValue = JSON.parse(e.target.value)
      setTempMandateUrn(urnValue)

    }
  }

  const finalOrderPurchase = (e) => {
    e.preventDefault()
    if (!paymentMode) {
      notify("error", "Please select payment mode")
      return
    }
    if (!bankName && paymentMode !== "M") {
      notify("error", "Please select bank name")
      return
    }
    if (paymentMode === "M" && !mandateUrn) {
      notify("error", "Please select mandate list")
      return
    }
    setLoader("block")
    let reqData = {
      email: tempProfileName.email,
      tax_status: tempProfileName.taxStatus,
      sub_trxn_type: "N",
      trxn_acceptance: "ALL",
      payment_mode: paymentMode,
      instrm_amount: totalInvestment,
      debit_amount_type: "",
      Return_paymnt_flag: paymentMode === "OL" || paymentMode === "UPI" ? imediateOrEmail : "N",
      Client_callback_url: "API URL",
      ach_exist: "Y",
      amc: "",
      product_code: "",
      reinvest: "",
      amount: "",
      input_ref_no: "",
      perpetual_flag: "N",
      insurance_enabled: "N",
      instrm_date: "",
      rtgs_code: paymentMode === "TR" ? tempBankName.IFSC_CODE : "",
      transfer_date: transferDate ? formatDate(transferDate) : "",
      utr_no: utrNo,
      umrn: paymentMode === "M" ? tempMandateUrn.UMRN_NO : "",
      folio: "",
      iin: tempProfileName.IIN,
      accountNo: paymentMode === "M" ? tempMandateUrn.ACCOUNT_NO : tempBankName.AC_NO,
      bank_code: paymentMode === "M" ? tempMandateUrn.BANK_CODE : tempBankName.BANK_CODE,
      ifsc_code: paymentMode === "M" ? "" : tempBankName.IFSC_CODE,
      branch: paymentMode === "M" ? "" : tempBankName.BRANCH_NAME,
      holder_name: tempProfileName.name,
      schemes: folioList
    }
    multiplePurchase(reqData, headerState).then(res => {
      if (res.data?.status === 200) {

        let nseArr = res.data?.data
        for (let i = 0; i < nseArr.length; i++) {
          let nseData = {
            Unique_No: nseArr[i].Unique_No,
            Trxn_No: nseArr[i].Trxn_No,
            Application_No: nseArr[i].Application_No,
            Fund: nseArr[i].Fund,
            Scheme: nseArr[i].Scheme,
            Scheme_Name: nseArr[i].Scheme_Name,
            Amt: nseArr[i].Amt,
            Status_Desc: nseArr[i].Status_Desc,
            Status_code: nseArr[i].Status_code,
            Platform: "Web",
            transaction_type: "New",
            iin: tempProfileName?.IIN,
            Pan: tempProfileName?.pan,
            Gpan: tempProfileName?.gpan,
            Joint1pan: tempProfileName?.JOINT1_PAN,
            Joint2pan: tempProfileName?.JOINT2_PAN,
            name: tempProfileName?.name,
          }
          saveNseTransaction(nseData, headerState).then(res => {
            refreshForProfile()
          })

        }
        setFolioList([])
        if (res.data?.data[0].Paymentlink) {
          window.open(res.data.data[0].Paymentlink)
        }
        setConfirmationData([])
        setShow(false);
        setConfirmShow(true);
        setConfirmationDataArr(res.data?.data)

      } else {
        setConfirmationDataArr([])
        setConfirmationData({})
        setErrorMsg(res.data?.msg)
        setErrorConfirmationShow(true);


      }
      setLoader("none")
    })
  }
  const deleteData = (id) => {
    folioList.splice(id, 1);
    setFolioList([...folioList])
  }
  const checkMandate = () => {
    if (profileName && mandateListData.length === 0) {
      setIsMandateAvailable(true)
      setHideMandate("d-none")
      setPaymentMode("");
    }
  }
  const dateAny = (id) => {
    const inputDateElement = document.getElementById(id);
    inputDateElement.showPicker();
  }
  return (
    <>
      <div className="">
        <Modal
          show={show}
          onHide={() => setShow(false)}
          className="animate__animated animate__zoomIn animate__fast invest-sip"
          centetext-red
        >
          {/* =========Investment Details======== */}
          <div className={`${Detail}`}>
            <Modal.Header className="bg-gray" closeButton>
              <Modal.Title className="fs-5">Investment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray">
              <form>
                <div className="col mb-3 ">
                  <Form.Label>
                    Select Profile <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="bg-c"
                    value={profileName}
                    onChange={profileChangeHandler}
                    required
                  >
                    <option value="">Select Profile</option>
                    {userProfileData.length > 0
                      ? userProfileData.map((el) => (
                        <option
                          value={JSON.stringify({
                            email: el.EMAIL,
                            taxStatus: el.TAX_STATUS,
                            IIN: el.IIN,
                            name: el.NAME,
                            pan: el.PAN,
                            gpan: el?.GUARD_PAN,
                            jtname1: el.JOINT_NAME1,
                            jtname2: el.JOINT_NAME2,
                            nominee: el.NOM_NAME,
                            JOINT1_PAN: el.JOINT1_PAN,
                            JOINT2_PAN: el.JOINT2_PAN,
                            JOINT1_VALIDPAN: el.JOINT1_VALIDPAN,
                            JOINT2_VALIDPAN: el.JOINT2_VALIDPAN,
                            bankName: el.BANK_NAME,
                            bankCode: el.BANK_CODE,
                            accountNo: el.AC_NO,
                            accountType: el.AC_TYPE,
                            ifscCode: el.IFSC_CODE,
                            branchName: el.BRANCH_NAME,
                            branchAddress: el.BRANCH_ADDRESS1,
                            hold_nature_desc: el.hold_nature_desc,
                          })}
                          key={el.PAN}
                        >
                          {`${el.NAME} ${el.JOINT_NAME1 && "|"} ${el.JOINT_NAME1
                            }`}
                        </option>
                      ))
                      : null}
                  </Form.Select>
                </div>
              </form>
              {folioList.length > 0
                ? folioList.map((data, i) => {
                  return (
                    <div className="cartitemwith">
                      <div className="row p-4">
                        <div className="col-md-11  text-red ">
                          {data?.scheme.PRODUCT_LONG_NAME}

                        </div>
                        <div className="col-1">
                          {folioList.length > 1 ? <Cart_Delete
                            id={i}
                            deleteData={deleteData}
                          /> : ""}

                        </div>


                      </div>
                      <div className="col bg-white py-3 px-4">
                        <Form.Label>
                          Select Folio <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          className="bg-c"
                          value={folioList[i].folio}
                          onChange={(e) => changeFolioHandler(e, i)}
                          disabled={folioLoader}
                        >
                          <option value="">New Folio</option>
                          {data.folioArr &&
                            data.folioArr.map((el) => (
                              <option value={el.folio} key={el.PAN}>
                                {el.folio}
                              </option>
                            ))}
                        </Form.Select>
                      </div>
                      <div className="col mb-3 bg-white pb-4 px-4 lastin">
                        <label htmlFor="amount" className="fs-14">
                          Enter Amount <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control border-pop "
                          name="amount"
                          placeholder="Enter Amount"
                          value={folioList[i].amount}
                          onChange={(e) => amountHandler(e, i)}
                        />
                        <small className="text-label float-right">
                          Min Amount : 5000
                        </small>
                      </div>
                    </div>
                  );
                })
                : null}
            </Modal.Body>
            <Modal.Footer className="border-0 bg-gray">
              <button
                type="button"
                className="btn btn-danger shadow-sm"
                onClick={continueFromProfile}
              >
                Continue
              </button>
            </Modal.Footer>
          </div>
          {/* =========confirm puchase======== */}
          <div className={`${Confirmp}`}>
            <Modal.Header className="bg-gray" closeButton>
              <span onClick={edit}>

                <BsArrowLeft className="sip-popup-rignt-icon" />
              </span>
              <Modal.Title className="fs-5">Confirm Purchase</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray">
              <div className="col mb-3 border-bottom">
                <h6 className="text-red">{tempProfileName?.name}</h6>
                <p>Mode of Holding : {tempProfileName?.hold_nature_desc} </p>
              </div>

              <p className="text-red"></p>
              <table className="table">
                {folioList.length > 0
                  ? folioList.map((data, i) => {
                    return (
                      <>
                        <tr>
                          <td colSpan="3">
                            <h6 className="text-red fw-500">
                              {data.scheme.PRODUCT_LONG_NAME}
                            </h6>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr className="text-center">
                          {data?.folio ? (
                            <>
                              <td className="text-start ps-5">Folio </td>
                              <td>:</td>
                              <td className="text-start ps-5">
                                {data.folio}
                              </td>
                            </>
                          ) : (
                            ""
                          )}
                        </tr>
                        <tr className="text-center">
                          <td className="text-start ps-5">Amount </td>
                          <td>:</td>
                          <td className="text-start ps-5">{data.amount}</td>
                        </tr>
                      </>
                    );
                  })
                  : null}
              </table>

              <p className="text-center pt-5">
                <b>Total : {totalInvestment}</b>
              </p>
            </Modal.Body>
            <Modal.Footer className="border-0 bg-gray">
              <button
                type="button"
                className="btn btn-danger shadow-sm"
                href="javascript:void(0);"
                onClick={continueFromDetail}
              >
                Continue
              </button>
            </Modal.Footer>
          </div>
          {/* =========Purchase======== */}
          <div className={`${Purchase}`}>
            <Modal.Header className="bg-gray" closeButton>
              <span onClick={edit}>

                <BsArrowLeft className="sip-popup-rignt-icon" />
              </span>
              <Modal.Title className="fs-5">Purchase</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-gray">
              <form onSubmit={finalOrderPurchase}>
                <p className="text-red">Investment Total : {totalInvestment}</p>
                <div className="col mb-3 ">
                  <Form.Label>
                    Select Payment Mode
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="bg-c"
                    value={paymentMode}
                    onChange={changePaymentModeHandler}
                  >
                    <option value="">Select Payment Mode</option>
                    <option value={"OL"}>Net Banking</option>
                    <option value={"UPI"}>UPI</option>
                    <option value={"TR"}>RTGS/NEFT</option>
                    <option value={"M"}>Debit Mandate</option>
                  </Form.Select>
                </div>
                <div className={`col-mb-3 ${hideBank}`}>
                  <Form.Label>
                    Select Bank <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    className="bg-c"
                    value={bankName}
                    onChange={changBankHandler}
                  >
                    <option value="">Select Bank</option>
                    {usersBankDetail &&
                      usersBankDetail.map((el) => (
                        el.STATUS ==="Activated" ?  <option
                          value={JSON.stringify({
                            AC_NO: el.AC_NO,
                            BANK_CODE: el.BANK_CODE,
                            IFSC_CODE: el.IFSC_CODE,
                            BRANCH_NAME: el.BRANCH_NAME,
                            BANK_NAME: el.BANK_NAME
                          })}
                        >{`${el.BANK_NAME} | Ac No- ${el.AC_NO}`}</option>:""
                      ))}
                  </Form.Select>
                </div>
                <div className={`col-mb-3 ${hideMandate}`}>
                  <Form.Group>
                    <Form.Label>
                      Select Mandate <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      className="bg-c"
                      value={mandateUrn}
                      onChange={changeMandateHandler}
                      onClick={checkMandate}
                    >
                      <option value={""}>Select Mandate</option>
                      {mandateListData.length > 0 &&
                        mandateListData.map((data) => (
                          <option
                            value={JSON.stringify({
                              UMRN_NO: data.UMRN_NO,
                              BANK_CODE: data.BANK_CODE,
                              ACCOUNT_NO: data.ACCOUNT_NO,
                              MANDATE_DATE: data.MANDATE_DATE,
                              AC_TYPE: data.AC_TYPE,
                            })}
                          >{`${data.BANK_NAME} | Ac No-  ${data.ACCOUNT_NO
                            } |  Amount- ${data?.AMOUNT > 0 ? data?.AMOUNT : "0"
                            } `}</option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className={`col-mb-3 ${hideRTGS}`}>
                  <Form.Group>
                    <Form.Label>UTR No.</Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="text"
                      placeholder="Enter UTR No"
                      value={utrNo}
                      onChange={(e) => {
                        if (e.target.value.length < 30) {
                          setUtrNo(e.target.value);
                        }
                      }}
                    />
                  </Form.Group>
                </div>
                <div className={`col-mb-3 ${hideRTGS}`}>
                  <Form.Group>
                    <Form.Label>Enter Transfer Date</Form.Label>
                    <Form.Control
                      className="bg-c"
                      type="date"
                      placeholder="Enter Amount"
                      max={futureDisabledDate}
                      value={transferDate}
                      id="anyWhere5"
                      onClick={() => dateAny("anyWhere5")}
                      onChange={(e) => setTransferDate(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className={`col ${hideImidiatePayment}`}>
                  <Form.Group
                    className="pt-lg-4 mt-lg-3 d-flex"
                    controlId="formBasicRadio"
                  >
                    <Form.Check
                      type="radio"
                      label="Immediate Payment"
                      name="formHorizont"
                      id="ImmediatePayment"
                      className="pe-3 fs-sm-14"
                      value={"Y"}
                      checked={imediateOrEmail == "Y"}
                      onChange={(e) => setImediateOrEmail(e.target.value)}
                    />
                    <Form.Check
                      type="radio"
                      label="Link On Email"
                      name="formHorizont"
                      id="Link On Email"
                      className="pe-3 fs-sm-14"
                      value={"N"}
                      checked={imediateOrEmail == "N"}
                      onChange={(e) => setImediateOrEmail(e.target.value)}
                    />
                  </Form.Group>
                </div>
                {
                  paymentMode === "TR" ? <div className="col mt-3">
                    <ul className="fs-12 mb-0 lst-none ps-0">
                      <li>
                        <h6 className="text-red fs-13">Note :-</h6>
                      </li>
                      <li className="text-label">

                        <span className="text-red">*</span>Beneficiary Name
                        <span className="text-black">
                          - ({`NSEMF221399${tempProfileName?.IIN}`})NSE Clearing – New Mutual Fund Platform
                        </span>
                      </li>
                      <li className="text-label">

                        <span className="text-red">*</span>Beneficiary Bank Name
                        <span className="text-black">
                          - HDFC Bank Ltd
                        </span>
                      </li>
                      <li className="text-label">
                        <span className="text-red">*</span>Beneficiary Branch Name
                        <span className="text-black">- Fort, Mumbai</span>
                      </li>
                      <li className="text-label">
                        <span className="text-red">*</span>Virtual Account No
                        <span className="text-black">- ({`NSEMF221399${tempProfileName?.IIN}`})</span>
                      </li>
                      <li className="text-label">
                        <span className="text-red">*</span>Account Type
                        <span className="text-black">- Current Account</span>
                      </li>
                      <li className="text-label">
                        <span className="text-red">*</span>IFSC Code (For RTGS / NEFT Transfers)
                        <span className="text-black">- HDFC0000060</span>
                      </li>
                    </ul>
                  </div> : ""}

                <Modal.Footer className="border-0 bg-gray">
                  <button type="submit" className="btn btn-danger shadow-sm">
                    Order
                  </button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </div>
          <SWW
            show={errorConfirmationShow}
            setShows={setErrorConfirmationShow}
            errorMsg={errorMsg}
          />
        </Modal>
        <TransactSuccess
          show={confirmShow}
          setShow={setConfirmShow}
          confirmData={confirmationData}
          confirmationDataArr={confirmationDataArr}
        />
        <ConfirmationMandatePopup
          show={isMandateAvailable}
          setShow={setIsMandateAvailable}
          profileDetail={{ name: tempProfileName?.name, iin: tempProfileName?.IIN,pan:tempProfileName?.pan,gpan:tempProfileName?.gpan }}
        />
      </div>
    </>
  );
}
export default LumpsumForm